import logo1 from "../img/TECHTAB.png";
import logo2 from "../img/MegaLogo.png";
import "bootstrap/dist/css/bootstrap.min.css";
import React, { useState, useEffect } from "react";

const Videos = () => {
  const [isMenuActive, setIsMenuActive] = useState(false);

  useEffect(() => {
    const handleMenuTriggerClick = () => {
      setIsMenuActive((prevState) => !prevState);
    };

    const $menuTrigger = document.getElementsByClassName("menu-trigger")[0];

    if ($menuTrigger) {
      $menuTrigger.addEventListener("click", handleMenuTriggerClick);
    }
    return () => {
      if ($menuTrigger) {
        $menuTrigger.removeEventListener("click", handleMenuTriggerClick);
      }
    };
  }, []);
  useEffect(() => {
    document.body.className = isMenuActive ? "menu-active" : "";
    const headerApplication = document.getElementById("header-application");
    if (window.innerWidth <= 575.98) {
      if (headerApplication) {
        console.log("test");
        document.getElementById("header-application").id = isMenuActive
          ? ""
          : "header-application";
      }
    }
  }, [isMenuActive]);

  const categories = [
    {
      name: "2139",
      videos: [
        {
          url: "https://drive.google.com/file/d/1NdTulKMATUWVPLTBL8sfVG67Siw0UFh9/preview",
          description: "1- انشاء حساب 2139",
        },
        {
          url: "https://drive.google.com/file/d/1LO5vESjYj80E2uc1T2UJf1knH61WPLjc/preview",
          description: "2- كيفية ادخال الاشارة",
        },
        {
          url: "https://drive.google.com/file/d/1BSiP6EyrwJElalkLfTf_fqjSyIBOyhPv/preview",
          description: "3- ادخال الاشارة بالتفصيل",
        },
        {
          url: "https://drive.google.com/file/d/1hFBsZYYPP-6IMyrws_ytvLKCm1NY9T70/preview",
          description: "4- التداول في حالة المراحل",
        },
        {
          url: "https://drive.google.com/file/d/16GVh5MPqMAZzTRbB1mj-eIRl2WfWGFMz/preview",
          description: "5- التداول في حالة المراحل (فيديو اخر)",
        },
      ],
    },
    {
      name: "Binance",
      videos: [
        {
          url: "https://drive.google.com/file/d/1SaZyly3yzCVyTMP-fRF2d4hDTHsXwt5V/preview",
          description: "1- انشاء حساب باينانس بالكامل",
        },
        {
          url: "https://drive.google.com/file/d/1tyusV2cRzzY6Z8dKIY6W1kPoXZPDMYxU/preview",
          description: "2- شراء عملات في باينانس و تحويلهم إلى 2139",
        },
        {
          url: "https://drive.google.com/file/d/1q3mtoo1gV7_88F9t8rmw_sSe8Pw2clRr/preview",
          description: "3- ملخص سريع شرح باينانس بشكل عام",
        },
        {
          url: "https://drive.google.com/file/d/1BdsQXoQ0-YyRdZ-AbMj5qfK4Mom0kPc7/preview",
          description: "4- بيع العملات الرقمية في باينانس و استلام الارباح",
        },
      ],
    },
    {
      name: "Commission",
      videos: [
        {
          url: "https://drive.google.com/file/d/1y_ie2wirGAdSIC8r1PrprLVXE0Wrolbq/preview",
          description: "1- تصنيف العمولات بشكل عام",
        },
        {
          url: "https://drive.google.com/file/d/1Eoc_8lkVzFzlmcvGPiLSHSJXRMWZkAEv/preview",
          description: "2- عمولة اضافة عضو جديد",
        },
        {
          url: "https://drive.google.com/file/d/1Rngxif0Yf4MMQcmuLIEcRKoUCkwvZ0ii/preview",
          description: "3- عمولة اضافة خمس اعضاء من نفس الحزمة",
        },
        {
          url: "https://drive.google.com/file/d/1JQCroiFct-ad7i3dZjecTUpa5dbCXzUn/preview",
          description: "4- عمولة الاشارات الاضافية",
        },
        {
          url: "https://drive.google.com/file/d/1rhzVV8W-Ou0oAKR0IIcmBnJl6FJKeS7Z/preview",
          description: "5- عمولة المستويات",
        },
        {
          url: "https://drive.google.com/file/d/1mHW8O6y6jpZ9DEBe0VbHsXfp9WbaA5n8/preview",
          description: "6- العمولة المستمرة",
        },
        {
          url: "https://drive.google.com/file/d/1YqGKMwkV_sAGoFIpqOuX4BjSy8F2Hlvt/preview",
          description: "7- عمولة كل اثنين",
        },
      ],
    },
    {
      name: "Other",
      videos: [
        {
          url: "https://drive.google.com/file/d/1o7WzQYJRanLDN41OrW5wYr-C06kRSb5J/preview",
          description: "1- حساب تجريبي",
        },
        {
          url: "https://drive.google.com/file/d/1XNZfjLHPzHr_OeWa56fyEShQAGWWO9mp/preview",
          description: "2- التبديل بين اجزاء المحفظة في 2139",
        },
        {
          url: "https://drive.google.com/file/d/1F_2XLF9haCQFHIexiViy1JfsgnRGl6_9/preview",
          description: "3- تغيير العملة",
        },
        {
          url: "https://drive.google.com/file/d/1-wm5ZkLOqDuZohkHOcv5fxSqQRjMmtPj/preview",
          description: "4- تحويل حسابك من تجريبي الى فعلي",
        },
        {
          url: "https://drive.google.com/file/d/1KodE3Zm7emBjjAl9zlOasVLcuh7-62Qg/preview",
          description: "5- اساسيات الاقناع للتسجيل بالتطبيق",
        },
        {
          url: "https://drive.google.com/file/d/1vMcexZseAYq_KVj_0sKWaMDJLs4keeD5/preview",
          description: "6- تحميل تطبيق 2139 على الايفون",
        },
        {
          url: "https://drive.google.com/file/d/1C7fy-ti9nhM7bf5oCMX4TLXIB38VNKPZ/preview",
          description: "7- انشاء جيميل جديد",
        },
        {
          url: "https://drive.google.com/file/d/1nUE3TOAVmkNLg2ndTd0FqUB93L-GXPK-/preview",
          description: "8 - نسبة عمولة السحب",
        },
      ],
    },
    {
      name: "Steps in Order",
      videos: [
        {
          url: "https://drive.google.com/file/d/1Nd3kJVJxWTBAuVI6Acg1Xt1Xg-jLlyp_/preview",
          description: "1- التسجيل في منصة 2139",
        },
        {
          url: "https://drive.google.com/file/d/131uXeQhX9TG1KRx2QG2t5MvLCf8r3cJH/preview",
          description: "2- التسجيل في باينانس",
        },
        {
          url: "https://drive.google.com/file/d/1jrIxJENdkj7FhRT11jrj0vOX4oxWCAsV/preview",
          description: "3- شراء عملات في باينانس و تحويلهم إلى 2139",
        },
        {
          url: "https://drive.google.com/file/d/1nfoCPHr6AtwgZ4EvCZ3iaRoGE_uVMlUV/preview",
          description: "4- التواصل مع الادمن لاجراءات اضاقة عضو جديد",
        },
        {
          url: "https://drive.google.com/file/d/1NUgCFm8LuOXy6OBYOa2KaimyF74x7GcF/preview",
          description: "5 -سحب الارباح (1)",
        },
        {
          url: "https://drive.google.com/file/d/1BzGIgk0vhyxikDtgES0JE-kvsm7MREGV/preview",
          description: "6 -سحب الارباح (2)",
        },
      ],
    },
  ];

  return (
    <div className="App">
      <nav id="side-menu">
        <img className="logo1" src={logo1} alt="Logo" />
        <ul>
          <li>
            <a href="/home">HOME</a>
          </li>
          <li>
            <a className="selected" href="/Videos">
              VIDEOS
            </a>
          </li>
          <li>
            <a href="/Calculate">CALCULATE</a>
          </li>
          <li>
            <a href="/Application">APPLICATION</a>
          </li>
          <li>
            <a href="/JoinUs">JOIN US</a>
          </li>
        </ul>
        <img className="logo2" src={logo2} alt="Logo" />
        <div id="under-logo">by TechTab</div>
      </nav>
      <div id="header-application" className="container">
        <div id="content">
          <div class="menu-trigger" />

          <div id="signals-videos" className="text-left">
            Videos
          </div>
          {categories.map((category, catIndex) => (
            <div key={catIndex}>
              <div id="signals-videos-category" className="text-left">
                {category.name}
              </div>
              <div className="row">
                {category.videos.map((video, index) => (
                  <div
                    key={index}
                    className="col-lg-4 col-md-6 col-sm-6 col-6 mb-4"
                  >
                    <div className="card">
                      <iframe
                        className="card-img-top"
                        src={video.url}
                        title="a"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                      />
                      <div className="card-body">
                        <p className="card-text video-description">
                          {video.description}
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
export default Videos;

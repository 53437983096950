import "../App.css";
import logo1 from "../img/TECHTAB.png";
import logo2 from "../img/MegaLogo.png";
import bg from "../img/HomePage.png";
import React, { useState, useEffect } from "react";

const Home = () => {
  const [isMenuActive, setIsMenuActive] = useState(false);

  useEffect(() => {
    const handleMenuTriggerClick = () => {
      setIsMenuActive((prevState) => !prevState);
    };

    const $menuTrigger = document.getElementsByClassName("menu-trigger")[0];

    if ($menuTrigger) {
      $menuTrigger.addEventListener("click", handleMenuTriggerClick);
    }
    return () => {
      if ($menuTrigger) {
        $menuTrigger.removeEventListener("click", handleMenuTriggerClick);
      }
    };
  }, []);
  useEffect(() => {
    document.body.className = isMenuActive ? "menu-active" : "";
    const headerApplication = document.getElementById("header");
    if (window.innerWidth <= 575.98) {
      if (headerApplication) {
        console.log("test");
        document.getElementById("header").id = isMenuActive ? "" : "header";
      }
    }
  }, [isMenuActive]);

  const myStyle = {
    backgroundImage: "url(" + bg + ")",
    backgroundPosition: "relative",
    backgroundRepeat: "no-repeat",
    backgroundSize: "100%",
  };

  return (
    <div className="App">
      <nav id="side-menu">
        <img class="logo1" src={logo1} alt="Logo" />
        <ul>
          <li>
            <a className="selected" href="/home">
              HOME
            </a>
          </li>
          <li>
            <a href="/Videos">VIDEOS</a>
          </li>
          <li>
            <a href="/Calculate ">CALCULATE</a>
          </li>
          <li>
            <a href="/Application">APPLICATION</a>
          </li>
          <li>
            <a href="/JoinUs">JOIN US</a>
          </li>
        </ul>
        <img class="logo2" src={logo2} alt="Logo" />
        <div id="under-logo">by TechTab</div>
      </nav>
      <div id="left-content">
        <div id="header">
          <div id="content">
            <div class="menu-trigger" />
            <div class="home-head">
              Join Us in <span>The TechTab Team</span>
            </div>
            <div id="home-content">
              TechTab is a Sub-Team for Mega Team with investment experts in
              financial consultancy business about 2139 exchange
            </div>
            <div class="background-image" style={myStyle}></div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Home;

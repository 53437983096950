import logo1 from "../img/TECHTAB.png";
import logo2 from "../img/MegaLogo.png";
import React, { useState, useEffect } from "react";

const Application = () => {
  const [isMenuActive, setIsMenuActive] = useState(false);

  useEffect(() => {
    const handleMenuTriggerClick = () => {
      setIsMenuActive((prevState) => !prevState);
    };

    const $menuTrigger = document.getElementsByClassName("menu-trigger")[0];

    if ($menuTrigger) {
      $menuTrigger.addEventListener("click", handleMenuTriggerClick);
    }
    return () => {
      if ($menuTrigger) {
        $menuTrigger.removeEventListener("click", handleMenuTriggerClick);
      }
    };
  }, []);
  useEffect(() => {
    document.body.className = isMenuActive ? "menu-active" : "";
    const headerApplication = document.getElementById("header-application");
    if (window.innerWidth <= 575.98) {
      if (headerApplication) {
        console.log("test");
        document.getElementById("header-application").id = isMenuActive
          ? ""
          : "header-application";
      }
    }
  }, [isMenuActive]);

  return (
    <div className="App">
      <nav id="side-menu">
        <img class="logo1" src={logo1} alt="Logo" />
        <ul>
          <li>
            <a href="/home">HOME</a>
          </li>
          <li>
            <a href="/Videos">VIDEOS</a>
          </li>
          <li>
            <a href="/Calculate ">CALCULATE</a>
          </li>
          <li>
            <a className="selected" href="/Application">
              APPLICATION
            </a>
          </li>
          <li>
            <a href="/JoinUs">JOIN US</a>
          </li>
        </ul>
        <img class="logo2" src={logo2} alt="Logo" />
        <div id="under-logo">by TechTab</div>
      </nav>
      <div id="header-application" class="container">
        <div id="content">
          <div class="menu-trigger" />
          <div id="to-help" class="text-left mb-4">
            WE ARE HERE TO HELP YOU!
          </div>
          <div class="row">
            <div
              id="appl-left"
              class="col-12 col-md-5 col-lg-4 p-4 mb-4 mb-md-0"
            >
              <div id="appl-package">1st Package</div>
              <div id="appl-100">$100</div>
              <div id="appl-features">Features:</div>
              <div id="appl-list">
                <ul>
                  <li>Capital Recovery Within 44 Days</li>
                  <li>Social Development</li>
                  <li>Working Time: 20 Min Per Day</li>
                  <li>Daily Profit Around 2%</li>
                </ul>
              </div>
              <div id="appl-get-started" className="text-center">
                <a href="https://forms.gle/FmM1XPX6ADQDNkUE8">
                  <input type="button" class="button" value="GET STARTED" />
                </a>
              </div>
            </div>
            <div id="appl-right" class="col-12 col-md-7 col-lg-8 p-4">
              <div id="appl-reg">To Register successfully!</div>
              <div id="appl-please">Please, read The Following!</div>
              <div id="appl-paragraph">
                <ul>
                  <li>
                    Candidates will be registered upon face-to-face meeting at a
                    specific agreed upon location.
                  </li>
                  <li>Please bring your ID or any proof of identity.</li>
                  <li>
                    The appropriate registration plan will be selected for each
                    accepted candidate at the time of the Meeting.
                  </li>
                  <li>
                    Other details will be discussed at the time of the meeting
                    (at the time of registration).
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Application;

import React, { useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import bg from "../img/HomePage.png";
import "../App.css";

const Login = () => {
  const [password, setPassword] = useState("");
  const { login } = useContext(AuthContext);
  const history = useHistory();

  const myStyle = {
    backgroundImage: "url(" + bg + ")",
    backgroundPosition: "fixed",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (password === "Khaled Ghanem") {
      console.log("Login successful");
      login();
      history.push("/home");
    } else {
      alert("Invalid credentials");
    }
  };

  return (
    <div>
      <div id="left-content" class="login-background">
        <div id="header">
          <div id="content">
            <div class="menu-trigger" />
            <div style={myStyle}>
              <div class="home-head">
                Join Us in <span>The TechTab Team</span>
              </div>
              <div id="home-content">
                TechTab is a Sub-Team for Mega Team with investment experts in
                financial consultancy business about 2139 exchange
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="login">
        <form class="login-from" onSubmit={handleSubmit}>
          <div>
            <div className="label-header">Enter the code</div>
            <label>the code is:</label>
            <div className="pass-input">
              <input
                type="text"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
          </div>
          <div class="login-get-started">
            <input type="submit" class="button" value="START" />
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;

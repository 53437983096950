import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Login from "./components/Login";
import Home from "./components/Home";
import Videos from "./components/Videos";
import Calculate from "./components/Calculate";
import JoinUs from "./components/JoinUs";
import Application from "./components/Application";
import NotFound from "./components/NotFound";
import AuthProvider from "./context/AuthContext";
import "./App.css";

function App() {
  return (
    <AuthProvider>
      <BrowserRouter>
        <Switch>
          <Route path="/" exact component={Login} />
          <Route path="/home" component={Home} />
          <Route path="/videos" component={Videos} />
          <Route path="/calculate" component={Calculate} />
          <Route path="/joinus" component={JoinUs} />
          <Route path="/application" component={Application} />
          <Route component={NotFound} />
        </Switch>
      </BrowserRouter>
    </AuthProvider>
  );
}

export default App;

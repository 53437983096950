const NotFound = () => {
  return (
    <div className="not-found">
      <title>Page Not Found</title>
      <div className="error-container">
        <h1 className="error-title">404</h1>
        <br />
        <p className="error-text">
          <h1 className="animate-blink">page not found</h1>
        </p>
      </div>
    </div>
  );
};

export default NotFound;

import logo1 from "../img/TECHTAB.png";
import logo2 from "../img/MegaLogo.png";
import React, { useState, useEffect } from "react";

const JoinUs = () => {
  const [isMenuActive, setIsMenuActive] = useState(false);

  useEffect(() => {
    const handleMenuTriggerClick = () => {
      setIsMenuActive((prevState) => !prevState);
    };

    const $menuTrigger = document.getElementsByClassName("menu-trigger")[0];

    if ($menuTrigger) {
      $menuTrigger.addEventListener("click", handleMenuTriggerClick);
    }
    return () => {
      if ($menuTrigger) {
        $menuTrigger.removeEventListener("click", handleMenuTriggerClick);
      }
    };
  }, []);
  useEffect(() => {
    document.body.className = isMenuActive ? "menu-active" : "";
    const headerApplication = document.getElementById("header-application");
    if (window.innerWidth <= 575.98) {
      if (headerApplication) {
        console.log("test")
        document.getElementById("header-application").id = isMenuActive
          ? ""
          : "header-application";
      }
    }
 }, [isMenuActive]);

  return (
    <div className="App">
      <nav id="side-menu">
        <img class="logo1" src={logo1} alt="Logo" />
        <ul>
          <li>
            <a href="/home">HOME</a>
          </li>
          <li>
            <a href="/Videos">VIDEOS</a>
          </li>
          <li>
            <a href="/Calculate ">CALCULATE</a>
          </li>
          <li>
            <a href="/Application">APPLICATION</a>
          </li>
          <li>
            <a className="selected" href="/JoinUs">
              JOIN US
            </a>
          </li>
        </ul>
        <img class="logo2" src={logo2} alt="Logo" />
        <div id="under-logo">by TechTab</div>
      </nav>
      <div id="header-application" className="container-fluid">
        <div id="content">
          <div class="menu-trigger" />
          <div className="col-12">
            <div id="follow" className="text-left h4">
              Follow Us:
            </div>
            <div id="main-container" className="container">
              <div id="grid-container" className="row">
                <div className="image-item col-lg-4 col-md-6 col-sm-6 col-6 mb-4 btn">
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://web.facebook.com/profile.php?id=61553240576135"
                  >
                    FACEBOOK PAGE
                  </a>
                </div>
                <div className="image-item col-lg-4 col-md-6 col-sm-6 col-6 mb-4 btn">
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://web.facebook.com/groups/3686210661650019"
                  >
                    FACEBOOK GROUP
                  </a>
                </div>
                <div className="image-item col-lg-4 col-md-6 col-sm-6 col-6 mb-4 btn">
                  <a target="_blank" rel="noreferrer" href="#">
                    INSTAGRAM
                  </a>
                </div>
                <div className="image-item col-lg-4 col-md-6 col-sm-6 col-6 mb-4 btn">
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://t.me/Khaled_OG"
                  >
                    TELEGRAM
                  </a>
                </div>
                <div className="image-item col-lg-4 col-md-6 col-sm-6 col-6 mb-4 btn">
                  <a target="_blank" rel="noreferrer" href="#">
                    ZOOM
                  </a>
                </div>
                <div className="image-item col-lg-4 col-md-6 col-sm-6 col-6 mb-4 btn">
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://m.me/150057294863645?source=qr_link_share"
                  >
                    MESSENGER
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default JoinUs;
